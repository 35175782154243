import * as React from 'react'
import { useLazyQuery } from '@apollo/react-hooks'

import {
  AddedToBasketModal,
  AddedToBasketModalSkeleton,
} from '@thg-commerce/enterprise-components/AddedToBasketModal'
import { removeCurrencySymbol } from '@thg-commerce/enterprise-components/Price'
import {
  EnterpriseContext,
  i18n,
  Routes,
  useEnterpriseContext,
  useLogger,
  useSiteConfig,
} from '@thg-commerce/enterprise-core'
import { pushToEventGA4 } from '@thg-commerce/enterprise-metrics/src/data_layer/pushToDataLayer/utils'
import {
  Country,
  Currency,
  Feature,
  ProductRecommendationType,
  Scalars,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import { useTheme } from '@thg-commerce/enterprise-theme'
import { Modal } from '@thg-commerce/gravity-elements'
import { AddedToBasketModalInterface } from '@thg-commerce/gravity-patterns/AddedToBasketModal/theme'
import { SubscriptionContract } from '@thg-commerce/leonardo/horizon-schema'

import { BasketContext } from '../BasketContext'

import { AddedToBasketModal as query } from './AddedToBasketModal.graphql'
import { addedToBasketModalDataTrackingCallbacks } from './dataTracking'

interface QueryVariables {
  sku: string
  currency: Currency
  shippingDestination: Country
  basketId: string
  loadProductRecommendations: boolean
  recommendationsType: ProductRecommendationType
  recommendationsLimit: number
  loyaltyEnabled: boolean
  vipPriceEnabled: boolean
  subscriptionContractsEnabled?: boolean
}

interface BasketItem {
  product: {
    sku: string
  }
  chargePrice: {
    displayValue: string
  }
  subscriptionContract: {
    id: string
  }
}

interface QueryResponse {
  product: {
    sku: string
    title: string
    url: string
    images: {
      largeProduct: string
    }
    price: {
      displayValue: string
    }
    rrp: {
      displayValue: string
    }
    subscriptionContracts: SubscriptionContract[]
    recommendations: {
      sku: string
      url: string
      title: string
      images: {
        largeProduct: string
      }
      externalIdentifier: string
      brand: {
        name: string
        imageUrl?: string
      }
      hasFromPrice: boolean
      price: {
        displayValue: string
      }
      rrp?: {
        displayValue: string
      }
      reviews: {
        total: number
        averageScore: number
      }
    }[]
  }
  addedToBasketModal: {
    basket: {
      totalQuantity: number
      standardPrice: {
        displayValue: string
      }
      items?: BasketItem[]
      earnableLoyaltyPoints: number | null
      chargePrice?: {
        displayValue: string
        currency: string
        amount: string
      } | null
    }
  }
}

const getBasketItemPrice = (
  product: QueryResponse['product'],
  basket: QueryResponse['addedToBasketModal']['basket'],
  sku: string,
  addedToBasketModalTheme: AddedToBasketModalInterface,
  i18nText: {
    saveLabel: string
    offLabel: string
  },
) => {
  const matchingItem = basket.items?.find((item) => item.product.sku === sku)
  return {
    price: {
      defaultPrice:
        matchingItem?.chargePrice.displayValue || product.price.displayValue,
    },
    ...(addedToBasketModalTheme.displayRrpPerUnit &&
      product.price.displayValue !== product.rrp.displayValue && {
        i18nText,
        rrp: {
          defaultPrice: product.rrp.displayValue,
        },
        showRrpInline: addedToBasketModalTheme.showRrpInline,
        savings: {
          show: addedToBasketModalTheme.savings.show,
          textStyle: addedToBasketModalTheme.savings.textStyle,
        },
        savingsPercentage: {
          show: addedToBasketModalTheme.savingsPercentage.show,
        },
      }),
  }
}

const isHorizonFeatureSupported = (
  feature: Feature,
  horizonFeatures: Feature[] | undefined,
  precondition: boolean = true,
) => (precondition && horizonFeatures?.includes(feature)) || false

export const AddedToBasketModalPresenter = () => {
  const logger = useLogger()
  const basketContext = React.useContext(BasketContext)
  const enterpriseContext = React.useContext(EnterpriseContext)
  const theme = useTheme()

  const [open, setOpen] = React.useState(false)
  const [quantity, setQuantity] = React.useState(0)

  const {
    value: [getHeaderHeight],
  } = enterpriseContext.headerHeightRef
  const headerHeight = getHeaderHeight()
  const { horizonFeatures } = useEnterpriseContext()
  const {
    removeFocusTrap,
    enableVipPrice,
    enableSavePrice,
    useGA4EnhancedEcom,
  } = useSiteConfig()

  const { currency } = useEnterpriseContext()

  const vipPriceEnabled = isHorizonFeatureSupported(
    Feature.VipPricingEnabled,
    horizonFeatures,
    enableVipPrice,
  )
  const loyaltyEnabled = isHorizonFeatureSupported(
    Feature.Loyalty,
    horizonFeatures,
  )
  const subscriptionContractsEnabled = isHorizonFeatureSupported(
    Feature.SubscribeAndSave,
    horizonFeatures,
  )

  const [loadData, { data, loading }] = useLazyQuery<
    QueryResponse,
    QueryVariables
  >(query)

  const willRender =
    !loading && data?.product && data?.addedToBasketModal?.basket

  React.useEffect(() => {
    if (willRender) {
      addedToBasketModalDataTrackingCallbacks.modalViewed()
    }
  }, [willRender])

  React.useEffect(() => {
    if (!useGA4EnhancedEcom || !data?.product?.recommendations) {
      return
    }

    pushToEventGA4({
      event: 'ecom_event',
      event_name: 'view_item_list',
      ecommerce: {
        currencyCode: currency,
        items: data.product.recommendations.map((recommendation, index) => ({
          index,
          item_name: recommendation.title || '',
          item_id:
            recommendation.externalIdentifier || recommendation.sku || '',
          item_brand: recommendation.brand?.name || '',
          item_list_name:
            i18nText.recommendedProductsTitle || recommendation.url || '',
          price: removeCurrencySymbol(recommendation.price.displayValue) || '',
        })),
      },
    })
  }, [useGA4EnhancedEcom, data?.product?.recommendations])

  const i18nText = {
    continueToBasketButtonText: i18n('general.viewbasket.label'),
    continueShoppingButtonText: i18n('general.continueshopping.label'),
    modalTitle: i18n('basket.productadded.modaltitle'),
    basketCountLabel: i18n('basket.subtotal.label'),
    quantityLabel: i18n('general.basket.qty.text'),
    singularBasketSizeText: i18n('basket.productadded.baskettotal.singular'),
    pluralBasketSizeText: i18n('basket.productadded.baskettotal.plural'),
    recommendedProductsTitle: i18n('basket.productadded.recommendedtitle'),
    loyaltyText: i18n(
      'basket.loyalty.text',
      String(data?.addedToBasketModal?.basket.earnableLoyaltyPoints),
    ),
    rrpLabel: i18n('general.rrp.text'),
    saveTextLabel: i18n('general.save.text'),
    savingTextLabel: i18n('product.price.saving.up.to.text'),
    offLabel: i18n('general.off.text'),
  }

  const productI18nText = {
    priceFromText: i18n('product.price.from.text'),
    reviewsStarsLabel: i18n('reviews.stars.label'),
    reviewsLabel: i18n('reviews.reviews.label'),
  }

  const modalI18nText = {
    closeAriaLabel: i18n('general.modal.close.button.arialabel'),
    closeLabel: i18n('general.modal.close.button.label'),
  }

  const screenReaderReviewText = ({
    score,
    total,
  }: {
    score: number
    total: number
  }) =>
    `${score} ${productI18nText.reviewsStarsLabel}
    ${total} ${productI18nText.reviewsLabel}`

  if (!basketContext.presentAddedToBasketModal) {
    logger.warn(
      'AddedToBasketModalPresenter: Failed to find an initialised BasketContext. Make sure presentAddedToBasketModal ref is defined and it wraps AddedToBasketModalPresenter.',
    )
    return null
  }

  basketContext.presentAddedToBasketModal.current = (
    sku: string,
    quantity: number,
  ) => {
    const {
      value: [getBasketId],
    } = basketContext.basketIdRef
    const basketId = getBasketId()

    if (!basketId) {
      logger.warn(
        'AddedToBasketModalPresenter: Failed to find a basketId from BasketContext. Ensure the service that renders AddedToBasketModalPresenter is using the withBasketContext HOC',
      )
      return
    }

    setOpen(true)
    setQuantity(quantity)

    loadData({
      variables: {
        basketId,
        loyaltyEnabled,
        vipPriceEnabled,
        subscriptionContractsEnabled,
        sku: sku as Scalars['SKU'],
        currency: enterpriseContext.currency as Currency,
        shippingDestination: enterpriseContext.shippingDestination
          .code as Country,
        loadProductRecommendations: true,
        recommendationsType: 'POST_ADD_TO_BASKET' as ProductRecommendationType,
        recommendationsLimit: 4,
      },
    })
  }

  if (loading && (!data?.product || !data?.addedToBasketModal?.basket)) {
    return (
      <Modal
        open
        onClose={() => setOpen(false)}
        i18nText={modalI18nText}
        showHeader={true}
        stickyHeader={true}
        headerOffset={headerHeight}
        gridColSpan={[12, 8, 8, 6]}
        data-testid={'added-to-basket-modal'}
      >
        <AddedToBasketModalSkeleton />
      </Modal>
    )
  }

  if (!data?.product || !data?.addedToBasketModal?.basket) {
    return null
  }

  const {
    product,
    addedToBasketModal: { basket },
  } = data || {
    product: {
      url: '',
      title: '',
      price: { displayValue: '' },
    },
    addedToBasketModal: {
      basket: {
        chargePrice: { displayValue: '0.00' },
        standardPrice: { displayValue: '0.00' },
        totalQuantity: 0,
        earnableLoyaltyPoints: null,
      },
    },
  }

  const recommendationBrand = (brand) => {
    if (!brand) return null
    return {
      name: brand.name ?? '',
      imageUrl: brand.imageUrl ?? '',
    }
  }

  const recommendationImage = (images) => {
    const largeProduct = images?.[0]?.largeProduct
    return largeProduct
      ? { urls: { largeProduct }, lazy: false, isAmp: false }
      : null
  }

  const recommendationReview = (reviews) => {
    if (!reviews) return null
    return {
      starRating: reviews.averageScore ?? 0,
      numberOfReviews: reviews.total ?? 0,
      screenReaderOnlyText: screenReaderReviewText({
        score: reviews.averageScore ?? 0,
        total: reviews.total ?? 0,
      }),
    }
  }

  const getPrice = (price, rrpPrice, displayPrice) => {
    const rrp =
      rrpPrice && rrpPrice !== displayPrice ? { defaultPrice: rrpPrice } : ''
    return (
      price && {
        rrp,
        price: { defaultPrice: price.displayValue || '' },
        priceFromText: productI18nText.priceFromText,
        hasFromPrice: price.hasFromPrice,
      }
    )
  }

  const recommendations = (product.recommendations || []).map(
    ({
      sku,
      title,
      brand,
      externalIdentifier,
      url,
      images,
      reviews,
      price,
      rrp,
    }) => {
      const displayPrice = price?.displayValue || ''
      const rrpPrice = rrp?.displayValue || ''

      return {
        sku,
        url,
        title: { value: title, useAlternateStyle: false },
        brand: recommendationBrand(brand),
        externalIdentifier: externalIdentifier ?? '',
        image: recommendationImage(images),
        review: recommendationReview(reviews),
        price: getPrice(price, rrpPrice, displayPrice),
      }
    },
  )

  return (
    <AddedToBasketModal
      loading={loading}
      removeFocusTrap={removeFocusTrap || false}
      rendering={{
        open,
        onClose: () => setOpen(false),
      }}
      product={{
        title: {
          value: product.title,
          useAlternateStyle: false,
        },
        url: product.url,
        image: {
          isAmp: false,
          urls: product.images?.[0] || {
            largeProduct: '',
          },
        },
        quantity: {
          quantity,
          text: i18nText.quantityLabel,
        },
        price: getBasketItemPrice(
          product,
          basket,
          product.sku,
          theme.patterns.addedToBasketModal,
          {
            saveLabel: i18nText.saveLabel,
            offLabel: i18nText.offLabel,
          },
        ),
        condensed: true,
        reversePriceAndQuantity: true,
      }}
      basket={{
        totalValue:
          enableVipPrice || basket.chargePrice?.displayValue
            ? basket.chargePrice?.displayValue
            : basket.standardPrice.displayValue || '0.00',
        quantity: basket.totalQuantity || 0,
        link: Routes.Basket,
        earnableLoyaltyPoints:
          data?.addedToBasketModal?.basket.earnableLoyaltyPoints,
      }}
      i18nText={i18nText}
      enableSavePrice={enableSavePrice}
      useGA4EnhancedEcom={useGA4EnhancedEcom}
      recommendations={recommendations}
      modal={{
        i18nText: modalI18nText,
        showHeader: true,
        stickyHeader: true,
        headerOffset: headerHeight,
        gridColSpan: [12, 8, 8, 6],
        'data-testid': 'added-to-basket-modal',
      }}
      userInteractionCallbacks={{
        closeClicked: () =>
          addedToBasketModalDataTrackingCallbacks.closeClicked(),
        continueShoppingClicked: () =>
          addedToBasketModalDataTrackingCallbacks.continueShoppingClicked(),
        checkoutClicked: () =>
          addedToBasketModalDataTrackingCallbacks.viewBasketClicked(),
      }}
      addToBasketModalTheme={theme.patterns.addedToBasketModal}
    />
  )
}
